import React, { useState, useEffect, FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { AUTH_PREFIX_PATH } from "../../../configs/AppConfig";
import { auth } from "../../../configs/FireBaseConf";
import { useAppDispatch } from "../../../redux/store";
import { selectUserIsLogin, setUserLogin, setUserLogout } from "../../../redux/userSlice";
import { authChangeState, getProfile } from "../../../services/FirebaseAuthService";

const AuthRoute: FC<any> = ({ children, ...rest }: any) => {

    const isLoggedIn = useSelector(selectUserIsLogin);
    const dispatchReduxToolkit = useAppDispatch();

    useEffect(() => {
        const AuthCheck = authChangeState(async (user: any) => {
            if (user) {
                try {
                    const userDetail = await getProfile();
                    if(userDetail && userDetail.is_active){
                        dispatchReduxToolkit(setUserLogin(userDetail));
                    }else{
                        dispatchReduxToolkit(setUserLogout());
                    }
                } catch (e) {
                    dispatchReduxToolkit(setUserLogout());
                }

            } else {
                dispatchReduxToolkit(setUserLogout());
            }
        });
        return () => AuthCheck();
    }, [auth]);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: AUTH_PREFIX_PATH,
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default AuthRoute;
