import React, {lazy, Suspense, useMemo, useState} from "react";
import {Switch, Route, Redirect} from "react-router-dom";

import Loading from "../../components/shared-components/Loading";
import {APP_PREFIX_PATH, CUSTOMER_PREFIX_PATH} from "../../configs/AppConfig";
import {RestaurantContext} from "../../context/restaurantContext";

export const AppViews = () => {

    const [restaurant, setRestaurant] = useState(null);

    const value = useMemo(() => ({ restaurant, setRestaurant }),[restaurant]);

    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <RestaurantContext.Provider value={value}>
                <Switch>
                    <Route path={`${APP_PREFIX_PATH}/tables`} component={lazy(() => import(`./tables`))}/>
                    <Route path={`${CUSTOMER_PREFIX_PATH}/:resId`}
                           component={lazy(() => import(`./tables/customer-route`))}/>
                    <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/tables`}/>
                </Switch>
            </RestaurantContext.Provider>
        </Suspense>
    )
}

export default React.memo(AppViews);
