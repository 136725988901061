import React, {FC} from "react";
import {Button} from "antd";
import './index.css';
import {ButtonProps} from "antd/lib/button/button";

interface ButtonCustomProps extends ButtonProps {
    active?: boolean;
    children?: React.ReactNode;

}

const ButtonCustom: FC<ButtonCustomProps> = (props, children) => {

    return (
        <Button className={`btn-custom ${props.active ? 'active' : ''}`} {...props}>{props.children}</Button>
    );
};

export default ButtonCustom;