import React, { FC, useEffect, useState } from "react";
import useDataListOnSnapshot from "./useDataListOnSnapshot";
import { getOrderListHistoryByShift, getOrderListHistoryInProcess, getShiftOpening } from "../services/FirebaseFirestoreService";

const useHistoryOrder = (resId: string) => {
  const [order, setOrder] = useState<any>([]);
  const [openShift, setOpenShift] = useState<any>([])
  const [errorGetHistoryInProcess, loadingHistoryInProcess, historyInProcess]: any = useDataListOnSnapshot(resId, getOrderListHistoryInProcess,openShift[0]?.open_time);
  const [errorShiftOpenItem, loadingShiftOpenItem, shiftOpenItem]: any = useDataListOnSnapshot(resId, getShiftOpening);
  const [errorGetHistory, loadingHistory, history]: any = useDataListOnSnapshot(resId, getOrderListHistoryByShift,openShift[0]?.id);

  const sortList = (list: any[]) => {
    return list.sort((item1: any, item2: any) => {
      return item2.createdAt.seconds - item1.createdAt.seconds
    })
  }

  useEffect(()=>{
    if (loadingHistory) return;
    if (!history) return;
    const newList = [...historyInProcess]
    history?.forEach((item: any)=>{
      newList?.push(item);
    })

    setOrder(sortList(newList));

  },[history])

  useEffect(()=>{
    if (loadingHistoryInProcess) return;
    if (!historyInProcess) return;
    const newList = [...history]
    historyInProcess?.forEach((item: any)=>{
      newList?.push(item);
    })

    setOrder(sortList(newList));
    
    
    // setOrder([...order]);
    
  },[historyInProcess])

  useEffect(()=>{
    if (loadingShiftOpenItem) return;
    if (!shiftOpenItem) return;
    setOpenShift(shiftOpenItem);
  },[shiftOpenItem])

  return order;
};

export default useHistoryOrder;
