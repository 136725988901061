import React from "react";
import { Layout, Grid } from "antd";
import {
  DIR_LTR,
  DIR_RTL,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "../../constants/ThemeConstant";
import utils from "../../utils";
import navigationConfig from "../../configs/NavigationConfig";
import HeaderNav from "../../components/layout-components/HeaderNav";
import Theme from "../../model/Theme";
import TopNav from "../../components/layout-components/TopNav";
import MobileNav from "../../components/layout-components/MobileNav";
import AppViews from "../../views/app-views";

const { Content } = Layout;
const { useBreakpoint } = Grid;

interface AppLayoutProps extends Theme {
  location: any;
}

export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
  locale,
  sideNavTheme,
  topNavColor,
  headerNavColor,
  mobileNav,
  currentTheme,
}: AppLayoutProps) => {
  const currentRouteInfo: any = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {

      return { paddingLeft: "0" };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  return (
    <Layout>
      {!location.pathname.includes("app/tables/detail") &&
        !location.pathname.includes("app/tables/home") &&
        !location.pathname.includes("customer") &&
        !location.pathname.includes("customer/exception/404") && (
          <HeaderNav
            isMobile={isMobile}
            navCollapsed={navCollapsed}
            locale={locale}
            sideNavTheme={sideNavTheme}
            navType={navType}
            topNavColor={topNavColor}
            headerNavColor={headerNavColor}
            mobileNav={mobileNav}
            currentTheme={currentTheme}
            direction={direction}
          />
        )}
      {isNavTop && !isMobile ? (
        <TopNav topNavColor={topNavColor} routeInfo={currentRouteInfo} />
      ) : null}
      <Layout className="app-container">
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div
            className={`app-content app-content-config ${
              isNavTop ? "layout-top-nav" : ""
            }`}
          >
            <Content>
              <AppViews />
            </Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && (
        <MobileNav
          hideGroupTitle={undefined}
          routeInfo={currentRouteInfo}
          sideNavTheme={sideNavTheme}
          mobileNav={mobileNav}
          navType={navType}
          headerNavColor={headerNavColor}
          currentTheme={currentTheme}
        />
      )}
    </Layout>
  );
};

export default React.memo(AppLayout);
