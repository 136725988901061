import antdViVN from 'antd/es/locale/vi_VN';
import viMsg from "../locales/vi_VN.json";

const ViLang = {
  antd: antdViVN,
  locale: 'vi-VN',
  messages: {
    ...viMsg
  },
};
export default ViLang;
