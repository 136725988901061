import { useState, useEffect } from "react";

export default function useDataListOnSnapshot(
  idRes: string,
  onSnapshot: any,
  secondCondition: any = null,
  thirdCondition: any = null
) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (idRes) {
      reLoadData();
    }
  }, [idRes, setData, secondCondition]);

  const reLoadData = async () => {
    setLoading(true);

    if (thirdCondition) {
      onSnapshot(
        idRes,
        secondCondition,
        (querySnapshot: any) => {
          const dataItems = querySnapshot.docs.map((doc: any) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setLoading(false);
          setData(dataItems);
        },
        (error: any) => {
          console.log(error);
          setLoading(false);
          setError(error);
        },
        thirdCondition
      );
    } else {
      onSnapshot(
        idRes,
        secondCondition,
        (querySnapshot: any) => {
          const dataItems = querySnapshot.docs.map((doc: any) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setLoading(false);
          setData(dataItems);
        },
        (error: any) => {
          console.log(error);
          setLoading(false);
          setError(error);
        }
      );
    }
  };

  return [error, loading, data];
}
