import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCustomerSetting, setCustomerApp, setCustomerAppId } from "../../../redux/customerSlice";
import { useHistory, useLocation } from "react-router-dom";
import {
  getRestaurantDetailByCustomerAppId,
  getRestaurantDetailByDeviceId,
  streamRestaurantDetailByDeviceId,
  updateRestaurantDevicesId,
} from "../../../services/FirebaseFirestoreService";
import TableList from "./tables-list";
import NotFoundPage from "../../public-views/components/NotFoundPage";
import HeaderNav from "../../../components/layout-components/HeaderNav";
import { Avatar, Button, Spin, notification } from "antd";
import { RootState } from "../../../redux/store";

const CustomerIframe = () => {
  const query = useQuery();
  // const customerAppId: string | null = query.get("device_id");
  const [customerAppId, setCustomerAppIdFunc] = useState(query.get("device_id"));
  const [resId, setResId] = useState<string | null | "start">("start");
  const [restaurant, setRestaurant] = useState<any | null>();
  const [loading, setLoading] = useState(false);
  const [no, setNo] = useState(1);
  const isCustomer = useSelector((store: RootState) => store.customer.isCustomer);
  const lastScreen = useSelector((store: RootState) => store.customer.lastScreen);
  const dispatch = useDispatch();
  const history = useHistory();
  const [newAppId, setNewAppId] = useState<any>();

  if (isCustomer) {
    history.push(lastScreen);
  }

  // const fetchData = async () => {
  //   setLoading(true);
  //   if (customerAppId) {
  //     const data: any = await getRestaurantDetailByDeviceId(customerAppId, false);

  //     if (data) {
  //       dispatch(setCustomerApp(true));
  //       dispatch(setCustomerAppId(customerAppId));
  //       setResId(data.id);
  //       setRestaurant(data);
  //     }
  //     setLoading(false);
  //   } else {
  //     setResId(null);
  //     setLoading(false);
  //   }
  // };

  const fetch = async () => {
    if (customerAppId) {
      console.log(customerAppId);

      const unsubscribe: any = streamRestaurantDetailByDeviceId(
        customerAppId,
        (data: any) => {
          let no = 0;
          data?.data().devices.forEach((item: any, index: number) => {
            if (item.code === customerAppId) no = index + 1;
          });
          setNo(no);
          if (no > 0) {
            localStorage.setItem("no", no + "");
          }
          const device = data?.data().devices[no - 1];
          if (device) {
            dispatch(setCustomerApp(true));
            dispatch(setCustomerAppId(customerAppId));
            setResId(data.id);
            setRestaurant(data.data());
          } else {
            setResId(null);
            setLoading(false);
          }
        },
        false
      );
      setLoading(false);
    } else {
      setResId(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchData();
    fetch();
  }, [customerAppId]);

  if (resId) {
    return (
      <>
        <div className="customer-app-header">
          {/* {restaurant?.imageDefault && <Avatar src={restaurant.imageDefault} />} */}
          <h1>{`No ${no}: ${customerAppId}`}</h1>
          {/* <Button
            onClick={() => {
              dispatch(setCustomerApp(false));
              dispatch(setCustomerAppId(""));
              setResId(null);
            }}
          >
            Logout
          </Button> */}
        </div>
        <TableList resIdProp={resId} />
      </>
    );
  } else if (loading) {
    return <Spin />;
  } else if (resId === null) {
    return (
      <div style={{ marginTop: "20vh" }}>
        <h1>{`No ${localStorage.getItem("no")}: ${customerAppId}`}</h1>
        <img width={300} src="/img/others/logo-login.jpg" alt="" />
        <h1 style={{ padding: "0 20px", marginTop: 50, fontSize: 18 }}>
          コード変更しました。新しいコードを入力してください
        </h1>
        <h1 style={{ padding: "0 20px", fontSize: 18 }}>Mã không tồn tại hoặc đã hết hạn!</h1>

        <div>
          <input
            style={{
              border: "1px solid black",
              outline: "none",
              padding: "7px 20px",
              marginBottom: 10,
              borderRadius: 5,
            }}
            type="text"
            value={newAppId}
            onChange={(e) => {
              setNewAppId(e.target.value);
            }}
            placeholder="New Code"
          />
        </div>

        <Button
          type="primary"
          onClick={async () => {
            const restaurant: any = await getRestaurantDetailByDeviceId(newAppId, true);
            if (restaurant) {
              await updateRestaurantDevicesId(
                restaurant.id,
                restaurant.devices.map((item: any) => {
                  if (newAppId === item.code) item.active = false;
                  return {
                    ...item,
                  };
                })
              );
            }
            dispatch(setCustomerAppId(newAppId));
            setCustomerAppIdFunc(newAppId);
            history.push(`/customer-iframe?device_id=${newAppId}`);
          }}
        >
          確認
        </Button>

        <h1 style={{ marginTop: 30, fontSize: 18 }}>スタッフをお呼びください！</h1>
      </div>
    );
  } else {
    return <></>;
  }
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default CustomerIframe;
