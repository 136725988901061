import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface ScreenObject {
  isShowAds?: boolean;
}

const initialState: ScreenObject = {
  isShowAds: false
};

export const screenSlice = createSlice({
  name: "screenReducer",
  initialState,
  reducers: {
    onChangeShowAds: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isShowAds: action.payload.isShowAds,
      };
    }
  },
});
export const getScreenInfoFromRedux = (rootState: RootState) => rootState.screen;
export const isShowAds = (rootState: RootState) => getScreenInfoFromRedux(rootState).isShowAds;
export const { onChangeShowAds } = screenSlice.actions;
export default screenSlice.reducer;
