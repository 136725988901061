import React from "react";
import { Drawer, DrawerProps } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import MenuContent from "./MenuContent";
import Logo from "./Logo";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { MobileNavProps } from "../../model/ui-components";
import { onMobileNavToggle } from "../../redux/themeSlice";
import Flex from "../shared-components/Flex";
import { NAV_TYPE_SIDE } from "../../constants/ThemeConstant";
import utils from "../../utils";
import { useAppDispatch } from "../../redux/store";

export const MobileNav = ({ sideNavTheme, mobileNav, routeInfo, hideGroupTitle, navType, headerNavColor, currentTheme, localization = true }: MobileNavProps) => {

  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };
  const dispatchReduxToolkit = useAppDispatch();

  const onClose = () => {
    onMobileNavToggle(false);
    dispatchReduxToolkit(onMobileNavToggle(false))
  };

  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()

  const drawerProps: DrawerProps = {
    placement: "left",
    closable: false,
    onClose: onClose,
    visible: mobileNav,
    bodyStyle: { padding: 5 }
  }


  return (
    <Drawer
      {...drawerProps}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo={true} navCollapsed={false} logoType={navMode} navType={navType} />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent type={NAV_TYPE_SIDE} {...props} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

export default MobileNav;
