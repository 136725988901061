import { TableOutlined, BorderlessTableOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from './AppConfig';

const tablesNavTree = [{
  key: 'tables',
  path: `${APP_PREFIX_PATH}/tables`,
  title: 'sidenav.tables',
  breadcrumb: false,
  submenu: [
    {
      key: 'tables-list',
      path: `${APP_PREFIX_PATH}/tables/list`,
      title: 'sidenav.tables.list',
      icon: TableOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'tables-detail',
      path: `${APP_PREFIX_PATH}/tables/detail`,
      title: 'sidenav.tables.detail',
      icon: BorderlessTableOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...tablesNavTree
]

export default navigationConfig;
