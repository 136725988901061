import React from 'react';
import { Grid } from 'antd';
import utils from '../../utils';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../constants/ThemeConstant';
import { LogoProps } from '../../model/ui-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (navCollapsed: boolean, navType: string, isMobile: boolean, mobileLogo: any) => {
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if (isMobile && !mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const getLogoDisplay = (isMobile: boolean, mobileLogo: any) => {
  if (isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

export const Logo = ({ mobileLogo, navCollapsed, navType }: LogoProps) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const isCustomerApp = useSelector((store: RootState) => store.customer.isCustomerApp)
  return (
    <div
      className={getLogoDisplay(isMobile, mobileLogo)}
      style={{ width: `${getLogoWidthGutter(navCollapsed, navType, isMobile, mobileLogo)}`, justifyContent: `${!isMobile ? "start" : "unset"}` }}>
      {!(navCollapsed && !isMobile) && <h1>AT-POS {isCustomerApp && 'Customer'}</h1>}
    </div>
  )
}

export default Logo;
