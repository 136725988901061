import React from "react";
import { Menu, Dropdown, Avatar, DropDownProps } from "antd";
import { useSelector } from "react-redux";
import { LogoutOutlined } from '@ant-design/icons';
import { logoutUser } from "../../services/FirebaseAuthService";
import { selectuser } from "../../redux/userSlice";
import { useAppDispatch } from "../../redux/store";
import { clearCustomerSetting, setCustomerApp } from "../../redux/customerSlice";

export const NavProfile = () => {

  const currentUser = useSelector(selectuser);
  const dispatch = useAppDispatch();

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={currentUser.photoURL} />
          <div className="pl-3">
            <h4 className="mb-0">{currentUser.displayName}</h4>
            <span className="text-muted">{currentUser.restaurant_name}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={1} onClick={e => ()=>{
            dispatch(clearCustomerSetting())
            alert(123)
          }}>
            <span onClick={()=>{
            dispatch(clearCustomerSetting())
            dispatch(setCustomerApp(false))
            logoutUser()}
            }>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  const navProfileDropDownProps: DropDownProps = {
    placement: "bottomRight",
    overlay: profileMenu,
    className: "app-header-avt",
    trigger: ['click']
  }

  return (
    <Dropdown {...navProfileDropDownProps}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={currentUser.photoURL} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default NavProfile;
