import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Timestamp } from "firebase/firestore";
import { RootState } from "./store";

export interface TableOrderRedux {
  table_id: string;
  table_name?: string;
  code: string;
  countUpdate?: number;
  detail?: any[];
  final_cost?: number;
  number_people?: number;
  status: string;
  total_amount?: number;
  total_amount_pretax?: number;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

const initialState: TableOrderRedux = {
  table_id: "",
  code: "",
  number_people: 0,
  status: "inprocess",
};

export const tableSlice = createSlice({
  name: "tableReducer",
  initialState,
  reducers: {
    resetTable: () => {
      return initialState;
    },
    addNumberPeople: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        number_people: action.payload.number_people,
        table_id: action.payload.table_id,
        table_name: action.payload.table_name,
      };
    }
  },
});
export const getTableInfoFromRedux = (rootState: RootState) => rootState.table;
export const selectNumberPeople = (rootState: RootState) => getTableInfoFromRedux(rootState).number_people;
export const { addNumberPeople, resetTable } = tableSlice.actions;
export default tableSlice.reducer;
