import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistReducer,
} from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'
import { useDispatch } from 'react-redux';
import userReducer from '../redux/userSlice';
import themeReducer from '../redux/themeSlice';
import cartReducer from '../redux/cartSlice';
import tableReducer from '../redux/tableSlice';
import staffReducer from '../redux/staffSlice';
import hodaiReducer from '../redux/hodaiSlice';
import screenReducer from '../redux/screenSlice';
import customerReducer from '../redux/customerSlice';
import localStorage from "redux-persist/es/storage";

const rootReducer = combineReducers({
    user: userReducer,
    theme: themeReducer,
    cart: cartReducer,
    table: tableReducer,
    staff: staffReducer,
    hodai: hodaiReducer,
    screen: screenReducer,
    customer: customerReducer,
});

const persistConfig = {
    key: 'rootDrFam',
    version: 1,
    storage: localStorage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RootState = ReturnType<typeof rootReducer>;
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {

        return getDefaultMiddleware({
            serializableCheck: false
        });
    }
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()
export default store;
