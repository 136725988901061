import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Timestamp } from "firebase/firestore";
import { RootState } from "./store";
import { Topping } from "../model/Food";

interface Type {
  isSelect: boolean;
  name: string;
  price: string;
}

interface Category {
  value: string;
}

interface Option {
  id: string;
  is_active: boolean;
  name: string;
  type: Type[];
}

export interface CartDetail {
  id: string;
  categoryPrint: string;
  printName: string;
  category_id: Category[];
  is_active: boolean;
  created?: Timestamp;
  createdAt?: Date;
  timeAddToCart?: Date;
  pricePostTax: number;
  amountPricePostTax: number;
  image: string;
  price: number;
  name: string;
  description?: string;
  note: string;
  noteOption: string;
  noteTopping: string;
  quantity: number;
  options: Option[];
  toppings: Topping[];
  amount: number;
  total_amount: number;
  total_amount_unit: number;
  total_amount_pretax: number;
  total_amount_pretax_unit: number;
  nameLanguages: any;
  is_buffet: boolean
}

const initialState: CartDetail[] = [];

export const foodSlice = createSlice({
  name: "cartReducer",
  initialState,
  reducers: {
    addFood: (state, action: PayloadAction<CartDetail>) => {
      const foodIndex = state.findIndex((food) => food.id === action.payload.id && food.note === action.payload.note);
      if (foodIndex !== -1) {
        state[foodIndex].amount += action.payload.quantity;
        state[foodIndex].quantity += action.payload.quantity;
        state[foodIndex].total_amount += action.payload.total_amount;
        state[foodIndex].total_amount_pretax += action.payload.total_amount_pretax;
        state[foodIndex].amountPricePostTax += action.payload.amountPricePostTax;
      } else {
        state.push({ ...action.payload, amount: action.payload.quantity });
      }
    },
    increaseQuantity: (state, action: PayloadAction<CartDetail>) => {
      const foodItem = state.find(food => food.id === action.payload.id && food.note === action.payload.note)
      if(foodItem) {
        foodItem.amount++;
        foodItem.quantity++;
        foodItem.amountPricePostTax = foodItem.total_amount_unit * foodItem.quantity;
        foodItem.total_amount = foodItem.total_amount_unit * foodItem.quantity;
        foodItem.total_amount_pretax = foodItem.total_amount_pretax_unit * foodItem.quantity;
      }
    },
    decreaseQuantity: (state, action: PayloadAction<CartDetail>) => {
      const foodItem = state.find(food => food.id === action.payload.id && food.note === action.payload.note)
      if(foodItem && foodItem.quantity !== 1) {
        foodItem.amount--;
        foodItem.quantity--;
        foodItem.amountPricePostTax = foodItem.total_amount_unit * foodItem.quantity;
        foodItem.total_amount = foodItem.total_amount_unit * foodItem.quantity;
        foodItem.total_amount_pretax = foodItem.total_amount_pretax_unit * foodItem.quantity;
      } else {
        state.splice(state.findIndex(food => food.id === action.payload.id && food.note === action.payload.note), 1);
      }
    },
    removeFood: (state, action: PayloadAction<any>) => {
      return state.filter((food) => food.id !== action.payload.id)
    },
    reset: (state) => {
      return initialState;
    },
  },
});
export const getFoodListFromRedux = (rootState: RootState) => rootState.cart;
export const { addFood, increaseQuantity, decreaseQuantity, reset, removeFood } = foodSlice.actions;
export default foodSlice.reducer;
